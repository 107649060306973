// #region Imports
import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Redirect,
  Switch,
  useHistory
} from "react-router-dom";
import API from "./components/axios-base-url";

// #region Local Imports
import "./App.css";
import { logDebug, logError } from "./components/logger";
import Utils from "./components/common/utils";
function handleErr(error) {
  logDebug(error);
  let errmsg = {};
  if (error && error.data) {
    switch (error.data.name) {
      case "JsonWebTokenError":
      case "TokenExpiredError":
        errmsg = {
          type: "jwt",
          name: error.data.name,
          message: error.data.message
        };
        break;
      default:
        if (error.data.message === "Token not authentic.") {
          errmsg = {
            type: "jwt",
            name: error.data.name,
            message: error.data.message
          };
        } else {
          errmsg = {
            message: error.data.message
          };
        }
        break;
    }
    return errmsg;
  }
}
const SuccessPageTimer = props => {
  const { state, authValue, message, success, timer, setTimer } = props || {};
  const {
    identifier = "",
    authType = "oauth2",
    token = "",
    domain = "thinkval",
    origUrl,
    sub_domain
  } = state;

  var redirectTimer = setInterval(() => {
    if (timer > 0) {
      setTimer(timer - 1);
    }
  }, 1000);
  useEffect(() => {
    if (timer <= 0) {
      clearInterval(redirectTimer);
      // redirect to
      window.location = origUrl;
    }
  }, [timer]);
  return (
    <div>
      <p>Authentication {success ? "Successful" : "Failed"}.</p>
      <p>{message}</p>
      <p>
        Redirecting you to {origUrl} in {timer}...
      </p>
      <span>
        If you are not redirected.{" "}
        <a
          className="App-link"
          href={origUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>
      </span>
    </div>
  );
};
const AuthPage = props => {
  const { state, authValue } = props || {};
  const {
    identifier = "",
    authType = "oauth2",
    token = "",
    sub_domain,
    code_verifier
  } = state;
  const [timer, setTimer] = useState(10);
  console.log("ValAuth: AuthPage props: ", props);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [message, setMessage] = useState("");
  // const showErrorMessage = () => {
  //   if (errorMsg !== "" && !success) {
  //     return <p>{errorMsg}</p>;
  //   }
  // };
  const saveAuth = async () => {
    try {
      if (!identifier || identifier === "") {
        throw {
          message: "Failed to save authentication. No third party app specified"
        };
      } else if (authValue && authValue.error) {
        // throw error message
        throw authValue;
      } else {
        if (identifier !== "" && authValue) {
          const resp = await API.post(
            `db/integration/saveAuth`,
            {
              identifier,
              authType,
              authValue: { ...authValue, code_verifier, state }
            },
            {
              params: {
                uuid: Utils.uuidv4(),
                token,
                sub_domain
              }
            }
          );
          if (resp && resp.data) {
            if (resp.data.message) {
              // error
              throw resp.data;
            } else if (resp.data.authentication) {
              localStorage.setItem(
                `${authType}.${identifier}`,
                JSON.stringify(authValue)
              );
              setMessage("");
              setSuccess(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err) {
        const errObj = handleErr(err.response, err);

        logError(
          "ValAuth getIntegrationFilters :: Failed to retrieve Fitlers",
          errObj
        );
        if (errObj) setMessage(errObj.message);
      }
    } finally {
      setProcessing(false);
    }
  };
  useEffect(() => {
    if (!success) {
      saveAuth();
    }
  }, []);

  return (
    <div>
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
      {!processing && (
        <SuccessPageTimer
          state={state}
          message={message}
          success={success}
          setTimer={setTimer}
          timer={timer}
        />
      )}
      {/* {errorMsg !== "" && success && <p>Saving...</p>} */}
    </div>
  );
};
const AllRoutes = props => {
  const location = useLocation();
  const { user } = props;
  // billing info
  // const { customer_info: customerInfo } = user || {};
  // const { billing_info: billingInfo, subscription } = customerInfo || {};
  // const { status: subscriptionStatus = "" } = subscription || {};
  // const { plan = "free" } = billingInfo || {};

  function tempExtractOAuth() {
    logDebug("App: OAuthView:", location);
    const { search } = location;

    var fragmentString = search.substring(1);

    // Parse query string to see if page request is coming from OAuth 2.0 server.
    var params = {};
    var regex = /([^&=]+)=([^&]*)/g,
      m;
    while ((m = regex.exec(fragmentString))) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
    if (Object.keys(params).length > 0) {
      try {
        if (params["state"] && params["state"] && typeof params["state"]) {
          params["state"] = JSON.parse(params["state"]) || {};
          logDebug("App: OAuthView: user found in params", params);
        }
      } catch (err) {
        logDebug("Failed to parse state: ", err);
      }
    }
    logDebug("App: OAuthView: params", params);
    const objToUse = {
      authValue: {
        ...params
      },
      state: params.state
    };
    delete objToUse.authValue.state;
    return objToUse;
    // save auth
    // const { token_type, access_token, expires_in } = params;
    // make use of it
  }

  function DisplayAuthPage(props) {
    logDebug(`App: DisplayOtpRequest called from `, location);
    const { hash, state } = location;
    logDebug(
      `App: DisplayOtpRequest is extracting: hash=${hash} state:${state}`
    );
    // Temp hack (to be replaced with in this week)
    const params = tempExtractOAuth();

    return <AuthPage {...props} {...params} />;
  }

  return (
    <Router>
      <Route
        path="/authorize"
        render={renderProps => <DisplayAuthPage {...renderProps} />}
      />
    </Router>
  );
};
function ValAuthApp() {
  const location = useLocation();
  return <AllRoutes />;
}
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <ValAuthApp />
        </Router>
      </header>
    </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>Saved authentication details</p>
    //     <p>This process is automatic. Redirecting you back in {timer}...</p>
    //     <span>
    //       If you are not redirected.{" "}
    //       <a
    //         className="App-link"
    //         href="https://reactjs.org"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Click here
    //       </a>
    //     </span>
    //   </header>
    // </div>
  );
}

export default App;
