export function logInfo(message) {
  console.log(`INFO: `, message);
}

export function logDebug(message, value) {
  if (process.env.NODE_ENV === "development") {
    // console.log(`DEBUG: ${stringify ? JSON.stringify(message) : message}`);
    if (value === undefined) {
      console.log("DEBUG: ", message);
    } else {
      console.log("DEBUG: ", message, value);
    }
  }
}

export function logError(message, value) {
  if (value === undefined) {
    console.log("ERROR: ", message);
  } else {
    console.log("ERROR: ", message, value);
  }
}

export function logWarn(message, value) {
  if (value === undefined) {
    console.log("WARN: ", message);
  } else {
    console.log("WARN: ", message, value);
  }
}
