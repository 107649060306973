import moment from "moment";
import { logDebug, logError } from "../../logger";
import _ from "lodash";

export default class Utils {
  /**
   * Generates Universally Unique IDentifier (UUID)
   * Source: https://stackoverflow.com/a/2117523
   *
   * Good for generating unique id
   */
  static uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  /**
   * Smallish unique id
   * @param {prefix} prefix
   */
  static uniqueId(prefix = "U-") {
    return (
      prefix +
      Math.random()
        .toString(36)
        .substr(2, 12)
    );
  }

  /**
   * Somewhat unique id that allows FIFO sorting
   * @param {prefix} prefix
   */
  static uniqueSortableId() {
    return Date.now();
  }

  static handleFormatDate(date, type) {
    let newDate = "";
    if (date && date !== "") {
      if (moment(date).isValid()) {
        if (type && type !== "") {
          newDate = moment(date).format(type);
        } else {
          // default "YYYY-MM-DD"
          newDate = moment(date).format("YYYY-MM-DD");
        }
      }
    }
    return newDate;
  }

  static handleValue(value, type) {
    switch (type) {
      case "percent":
        return value * 100;
      default:
        return value;
    }
  }

  static appendCaret(_value, format) {
    let newValue = "";
    const { useCaret = false } = format || {};
    try {
      if (useCaret) {
        if (Number(_value)) {
          // check
          if (Number(_value) > 0) {
            newValue = `▲`;
          } else if (Number(_value) < 0) {
            newValue = `▼`;
          } else {
            // zero
          }
        }
      }
    } catch (err) {
      newValue = "";
      logError("Utils: Failed to get appended caret: ", err);
    } finally {
      return newValue;
    }
  }
  static returnColorFromRules(_value, format, color) {
    let newColor = color;
    const { positive = "#7ED321", negative = "#D0021B", zero = "#9B9B9B" } =
      format || {};
    try {
      if (Number(_value) || _value == "0") {
        logDebug("Utils: returnColorFromRules: ", {
          format,
          _value
        });
        // check
        if (Number(_value) > 0) {
          newColor = positive;
        } else if (Number(_value) < 0) {
          newColor = negative;
        } else {
          newColor = zero;
          // zero
        }
      }
    } catch (err) {
      newColor = color;
      logError("Utils: Failed to get conditional color: ", err);
    } finally {
      return newColor;
    }
  }
  static returnConditionalColoring(_value, format, color) {
    let newValue = { color, value: "" };
    const {
      conditionalRendering = false,
      useCaret = false,
      positive = "#7ED321",
      negative = "#D0021B",
      zero = "#9B9B9B"
    } = format || {};
    try {
      if (conditionalRendering) {
        newValue.color = this.returnColorFromRules(_value, format, color);
        newValue.value = this.appendCaret(_value, format);
      }
    } catch (err) {
      newValue = { color, value: "" };
      logError("Utils: Failed to get conditional color with caret: ", err);
    } finally {
      return newValue;
    }
  }
  static getDisplayValue(_value, format) {
    try {
      logDebug(`Utils.getDislayValue`, format);
      const {
        suffix = ``,
        prefix = ``,
        displayUnit = "",
        decimalPlace = "2",
        negativeValue = "",
        formatType = "",
        formatter,
        thousandSeparator = false,
        conditionalRendering = false,
        useCaret = false,
        positive = "#7ED321",
        negative = "#D0021B",
        zero = "#9B9B9B"
      } = format || {};
      switch (formatter) {
        case "date":
          const newDate = this.handleFormatDate(_value, formatType);
          return newDate;
        case "currency":
        case "number":
        case "percent":
          let newValue = _value;
          if (!isNaN(_value)) {
            newValue = this.handleDisplay(_value, displayUnit);
            newValue = this.handleDecimal(newValue, decimalPlace);
            newValue = this.handleSeparator(newValue, thousandSeparator);
            newValue = this.handleNegative(newValue, negativeValue);
            newValue = this.handleValue(newValue, formatter);
            if (!(displayUnit === "" || displayUnit === "none")) {
              newValue = `${newValue}${displayUnit}`;
            }

            return `${prefix}${newValue}${suffix}`;
          }
          logDebug(`BigNumberValue: not int`, _value);
          break;
        default:
          break;
      }
      return _value || "";
    } catch (err) {
      throw err;
    }
  }

  static handleSeparator(_value, thousandSeparator) {
    if (thousandSeparator === true) {
      return _value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return _value;
  }

  static handleDecimal(_value, decimalPlace) {
    if (decimalPlace !== "") {
      logDebug(
        `BigNumberValue: decimalPlace`,
        parseFloat(_value).toFixed(decimalPlace)
      );
      if (parseFloat(_value).toFixed(decimalPlace) === 0) {
        return 0;
      }
      return parseFloat(_value).toFixed(decimalPlace);
    }
    return _value;
  }

  static handleNegative(_value, negativeValue) {
    if (negativeValue !== "" && Math.sign(_value) === -1) {
      switch (negativeValue) {
        case "-":
          return `${_value}`;

        default:
          return `(${Math.abs(_value)})`;
      }
    }
    return _value;
  }

  static handleDisplay(_value, displayUnit) {
    if (displayUnit !== "") {
      let newValue = _value;
      switch (displayUnit) {
        case "K":
          newValue = _value / 1000;
          break;
        case "M":
          newValue = _value / 1000000;
          break;
        case "B":
          newValue = _value / 1000000000;
          break;
        default:
          break;
      }
      return `${newValue}`;
    }

    return _value;
  }

  static handleFieldDisplay(fields, fieldToFind) {
    logDebug("Utils handleFieldDisplay:: ", { fields, fieldToFind });
    if (fields.length > 0) {
      const { aggregate, field, column_name } = fieldToFind;
      let foundField;
      if (field) {
        foundField = _.find(fields, { column_name: field });
      } else {
        foundField = _.find(fields, { column_name: column_name });
      }
      logDebug("GroupAggregatesortview. handleFieldDisplay:::", {
        fields,
        fieldToFind
      });
      logDebug("GroupAggregatesortview. handleFieldDisplay:::", {
        foundField,
        aggregate
      });
      if (aggregate && foundField) {
        const toReturn = {
          ...foundField,
          name: `${aggregate.toUpperCase()} ${foundField.name}`
        };

        return toReturn;
      }
      return foundField;
    }

    return fieldToFind;
  }
  static isCalculatedField(field) {
    return field && (field.type === "formula" || field.type === "rollup");
  }
}
