import axios from "axios";

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080/"
      : // In production, it should just be rendered from the same server
        "/",
  timeout: 7000
});
